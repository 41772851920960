<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Выберите версию для утверждения
      </v-card-title>
      <v-divider />
      
      <v-card-subtitle>
        <VersionSelection
          :filter="filter"
          :selected="selected"
          @selectVersion="onVersionSelected"
          @versionsCount="checkAvailableDialog"
        ></VersionSelection>
      </v-card-subtitle>
      <v-card-text class="pt-4">
        <p>Укажите дату утверждения версии маршрута</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="approveQuery"
              :loading="loading"
              :disabled="!date"
            >
              Утвердить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <component
      :is="component"
      :messages="messages"
      :collection="this.collection"
      :routeId="this.selected.vcroutesId"
      :verId="this.selected.vcroutesVerid"
      :date="this.date"
      :userId="this.selected._sec_attrsUserid"
      :tenantId="this.selected._sec_attrsTenantid"
      :selected="this.selected"
      @onApply="approveQuery"
    ></component>
  </v-dialog>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

const PROJECT_STATUS_GUID = 'ed4762ee-ca91-4b52-89e8-1176fb0a296e';

import Vue from "vue";

import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';

export default {
  name: "ApproveRouteVersion",
  components: {
    VersionSelection,
  },
  props: {
    collection: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    dialog: true,
    loading: false,
    component: null,
    item: null,
    block: false,
    selected: null,
    messages: [],
    components: [
      {name: 'AcceptanceErrors',},
      {name: 'AcceptanceWarnings',}
    ],
    dayOfWeekToFormat: () => '',
    filter: '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;

    if (!this.selected) {
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }
    
    this.filter = `and(eq(field(".verID"),param("${this.selected.vcroutesVerid}","id")),eq(field(".verStatus"),param("${PROJECT_STATUS_GUID}","id"))`;
  },
  methods: {
    async approveQuery(messages) {
      let component = '';
      let optionsQuery = {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.aproveRoute',
        params: {
          in_routeId: this.selected.vcroutesId,
          in_verId: this.selected.vcroutesVerid,
          in_date: this.date,
          in_userId: this.selected._sec_attrsUserid,
          in_tenantId: this.selected._sec_attrsTenantid,
          in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
          in_mode: 1,
        }
      }
      try {
        const resp = await jet.http.post(optionsQuery);
        if (resp.error) {
          throw resp.error;
        }

        const arr = resp.result.data;
        for (var i = 0; i < arr.length; i++){
          var item = arr[i];
          (this.messages).push(JSON.parse(item[0]));
          
          if (this.messages[i].response.is_block) {
            component = this.components[0];
            break;
          } else {
            component = this.components[1];
          }
        }

        this.component = component.name;
        const checkImport = import(`./components/${this.component}`);
        //TODO: разобраться почему catch выбрасывается раньше, чем выводится сообщение об отсутсвии компонента
        if (!checkImport) {
          jet.msg({
            text: `Компонент ${this.component} не найден!`,
            color: 'warning'
          });
        } else {
          Vue.component(
            this.component,
            () => import('./components/' + this.component)
          );
        }

      } catch (e) {
        jet.msg({
          text: 'Не удалось утвердить версию маршрута.',
          color: 'warning'
        });
        console.error('ApproveRouteVersion: approveQuery()', e);
        return;
      }
      this.loading = false;
      this.dialog = false;
    },
    onVersionSelected(item) {
      this.selected = item;
      this.version = this.selected.vcroutesVersion;
    },
    checkAvailableDialog(count) {
      if (count === 0) {
        this.dialog = false;
        jet.msg({
          text: 'Действие доступно только для статуса «Проект», у маршрута нет версий с таким статусом',
          color: 'warning'
        });
        return; 
      }
    },
  }
}
</script>

